<template>
  <div>
    <div class="container">
      <div class="text-center" style="color: #487b54">
        Nearly there, just one last step
      </div>
      <h1 class="text-center mb-5" style="color: #97c03b; font-size: 4rem">
        We need you to confirm
        <br />you're not a robot...
      </h1>
      <img
        src="@/assets/images/newsletter/subs-steps.png"
        class="mb-5"
        style="width: 100%; padding: 1rem"
      />
      <div>
        <h3 class="mb-3" style="color: #97c03b">
          Before you can receive email updates from Amchara Health Retreats you
          need to confirm your email right now.
          <i>(It’s easy!)</i>
        </h3>
        <p>
          Check your email inbox and locate the confirmation email (subject:
          "Amchara Health Retreats - Please confirm your subscription"). Follow
          the steps in the email to confirm your email address.
        </p>
        <p>
          Make sure you check your spam box as it sometimes gets caught there.
        </p>
        <p>
          Please also ensure that you “white-list” the email address in order to
          ensure that future emails don’t get lost.
        </p>
        <b style="color: #97c03b">
          <i
            >Once you have confirmed your email address, we will be able to send
            you email updates.</i
          >
        </b>
      </div>
    </div>

    <featured-mags></featured-mags>
    <testimonials></testimonials>
  </div>
</template>

<script>
import FeaturedMags from "./page-partials/FeaturedMags";
import Testimonials from "./page-partials/Testimonials";
export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  components: {
    FeaturedMags,
    Testimonials,
  },
};
</script>

<style>
</style>